import { BookingStatusApiResponseEnum } from '../../booking-request';

export interface RoomsListApiResponseModel {
  minCapacity: number;
  features: RoomFeaturesApiResponseModel[];
  id: number;
  calendarId: string;
  dexId: string;
  roomDexId: string;
  siteName: string;
  buildingName: string;
  floorName: string;
  name: string;
  isFavorite: boolean;
  isInstantBooking: boolean;
  capacity: number;
  mainPhoto: string;
}

export interface RoomApiResponseModel {
  roomDexId: string;
  name: string;
  description: string;
  manageSpace: boolean;
  photo: {
    id: number;
    name: string;
    url: string;
    main: boolean;
  };
  type: string;
  capacity: number;
  siteName: string;
  buildingName: string;
  floorName: string;
}

export interface RoomAvailabilityApiResponseModel {
  ranges: [
    {
      start: string;
      end: string;
      organizer: string;
      creator: string;
      title: string;
      bookingId?: string;
      type?: CalendarEventTypeEnum;
      userFullName?: string;
      status?: BookingStatusApiResponseEnum;
      eventName?: string;
    },
  ];
}

export enum CalendarEventTypeEnum {
  Event = 'EVENT',
  BlockingEvent = 'BLOCKING_EVENT',
  BeforeSetupEvent = 'BEFORE_SETUP_EVENT',
  AfterSetupEvent = 'AFTER_SETUP_EVENT',
}

export interface RoomDetailsApiResponseModel {
  id: number;
  calendarId: string;
  roomDexId: string;
  roomName: string;
  roomNumber: string;
  roomType: string;
  active: boolean;
  capacity: number;
  minCapacity: number;
  manageSpace: boolean;
  description: string;
  automaticApproval: boolean;
  siteName: string;
  buildingName: string;
  buildingNumber: string;
  street: string;
  floorCode: string;
  siteTimeZone: string;
  siteTimeZoneOffset: string;
  manageSpaceConfiguration?: RoomSettingsApiRequestModel;
  photos: Photo[];
  features: Feature[];
}

export interface RoomFiltersApiResponseModel {
  locations: RoomLocationsApiResponseModel[];
  roomTypes: RoomTypesApiResponseModel[];
  roomFeatures: {
    roomMustInclude: RoomFeaturesApiResponseModel[];
    roomTypeSetup: RoomFeaturesApiResponseModel[];
  };
}

export interface RoomTypesApiResponseModel {
  name: string;
}

export interface RoomFeaturesApiResponseModel {
  id: number;
  name: string;
}

export interface RoomLocationsApiResponseModel
  extends RoomLocationsBaseApiResponseModel {
  buildings: RoomBuildingsApiResponseModel[];
}

export interface RoomBuildingsApiResponseModel
  extends RoomLocationsBaseApiResponseModel {
  floors: RoomLocationsBaseApiResponseModel[];
}

export interface RoomLocationsBaseApiResponseModel {
  id: number;
  name: string;
}

export interface Photo {
  id: number;
  name: string;
  url: string;
  main: boolean;
}

export interface Feature {
  id: number;
  name: string;
}

export interface RoomsByEmailApiResponseModel {
  hostEmail: string;
  roomDexIds: string[];
}

export interface RoomLayoutUploadApiRequestModel {
  fileName: string;
  layoutName: string;
  setupTime: {
    before: number;
    after: number;
  };
  capacity: {
    min: number;
    max: number;
  };
  roomDexId: string;
  adminEmail: string;
}

export interface RoomLayoutUploadApiResponseModel {
  presignedUrl: string;
  roomLayoutDTO: {
    layoutName: string;
    roomDexId: string;
    layoutDexUUID: string;
    imageName: string;
    adminEmail: string;
    capacity: {
      min: number;
      max: number;
    };
    setupTime: {
      before: number;
      after: number;
    };
    createTime: string;
  };
}

export interface RoomLayoutsApiResponseModel {
  layoutDexUUID: string;
  imageURL: string;
  layoutName: string;
  capacity: {
    min: number;
    max: number;
  };
  setupTime: {
    before: number;
    after: number;
  };
}

export interface RoomSettingApiResponseModel {
  dexId: string;
  roomName: string;
  siteName: string;
  buildingName: string;
  floorName: string;
  roomType: string;
  manageSpaceConfiguration?: RoomSettingsApiRequestModel;
}

export interface RoomSettingsApiRequestModel {
  roomDexId: string;
  active?: boolean;
  description?: string;
  automaticApproval?: boolean;
  defaultLayoutId?: string;
  blockingTimes?: RoomSettingsBlockingTimeModel[];
  durationRange?: {
    min: RoomSettingsDurationRangeModel;
    max: RoomSettingsDurationRangeModel;
  };
}

export interface RoomSettingsDurationRangeModel {
  days: number;
  hours: number;
  minutes: number;
}

export interface RoomSettingsBlockingTimeModel {
  from: string;
  to: string;
  uuid: string | null;
}

export interface LocationsApiResponseModel {
  id: string;
  name: string;
  buildings: BuildingApiResponseModel[];
}

export interface BuildingApiResponseModel {
  id: string;
  name: string;
  floors: FloorApiResponseModel[];
}

export interface FloorApiResponseModel {
  id: string;
  name: string;
}

export interface RoomsFlatApiResponseModel {
  id: string;
  calendarId: string;
  dexId: string;
  roomDexId: string;
  siteName: string;
  buildingName: string;
  floorName: string;
  name: string;
  isFavorite: boolean;
  isInstantBooking: boolean;
  capacity: number;
  mainPhoto: string;
}

export interface RoomQueryParamsApiModel {
  roomDexId?: string;
  roomTypes?: string[];
  roomName?: string;
  isManageSpace?: boolean;
  minCapacity?: number;
  maxCapacity?: number;
  floorId?: number;
  siteId?: number;
  buildingId?: number;
  featureIds?: number[];
  isInstantBooking?: boolean;
  fromDate?: string;
  toDate?: string;
}
