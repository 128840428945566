import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.token && authService.user) {
    return true;
  }
  if (!sessionStorage.getItem('entryRoute') && !state.url.includes('?code')) {
    sessionStorage.setItem('entryRoute', state.url);
  }
  return router.navigate(['/login'], {
    queryParams: route.queryParams,
  });
};
