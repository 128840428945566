@if (details) {
  <div class="summary-container">
    <div class="summary-details">
      <p class="ui-body-m-bold summary-details-item">Booking Details:</p>
      <div class="summary-details-item">
        <rds-icon namespace="outlined" icon="flag" class="icon" />
        <div>
          <p class="ui-body-s-bold">Event Name:</p>
          <p class="ui-body-m">
            {{ details.eventName }}
          </p>
        </div>
      </div>
      <div class="summary-details-item">
        <rds-icon namespace="outlined" icon="calendar" class="icon" />
        <div>
          <p class="ui-body-s-bold">Date & Time:</p>
          <p class="ui-body-m">
            {{ details.startTime | eventDateTime: details.endTime }}
          </p>
        </div>
      </div>
      <div class="summary-details-item">
        <rds-avatar size="m" class="icon">
          @if (details.requester.photo) {
            <img
              rds-avatar-image
              [ngSrc]="details.requester.photo"
              fill
              referrerpolicy="no-referrer"
              alt="user image" />
          } @else {
            <rds-icon rds-avatar-icon namespace="outlined" icon="user" />
          }
        </rds-avatar>
        <div>
          <p class="ui-body-s-bold">Created by</p>
          <p class="ui-body-m">
            {{
              details.requester.fullName + ' (' + details.requester.email + ')'
            }}
          </p>
        </div>
      </div>
    </div>
    @if (selectedServices.length > 0) {
      <div class="summary-services">
        <p class="ui-body-m-bold summary-details-item">
          Based on your Additional Service Requests you can now complete the
          following:
        </p>
        @for (item of selectedServices; track item.service) {
          <div class="summary-details-item">
            <rds-custom-icon
              [icon]="item.customIcon"
              class="icon"></rds-custom-icon>
            <div>
              <p class="ui-body-s-bold">{{ item.name }}</p>
              @if (item.isUrl) {
                <a class="ui-body-m" [href]="item.description" target="_blank"
                  >Request here</a
                >
              } @else {
                <p class="ui-body-m">{{ item.description }}</p>
              }
            </div>
          </div>
        }
      </div>
    }
  </div>
}
