import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  ViewChild,
  inject,
} from '@angular/core';
import {
  RDS_DIALOG_DATA,
  RdsCarouselComponent,
  RdsCarouselModule,
} from '@rds/angular-components';

import { Photo } from '@bookly/shared';

export interface PhotosConfiguration {
  photos: Photo[];
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bsh-photos',
  imports: [CommonModule, RdsCarouselModule, NgOptimizedImage],
  templateUrl: './photos.component.html',
  styleUrl: './photos.component.scss',
})
export class PhotosComponent {
  private readonly data = inject(RDS_DIALOG_DATA) as PhotosConfiguration;
  protected readonly photos = this.data.photos;

  @ViewChild(RdsCarouselComponent) carousel: RdsCarouselComponent | null = null;

  @HostListener('document:keyup', ['$event'])
  KeyUpEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.carousel?.prev();
    }
    if (event.key === 'ArrowRight') {
      this.carousel?.next();
    }
  }
}
