import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { RdsToastService } from '@rds/angular-components';
import { catchError } from 'rxjs';

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const rdsToastService = inject(RdsToastService);
  return next(req).pipe(
    catchError(error => {
      // 403 - forbidden is processed by auth-error interceptor; 504 is also being processed by same interceptor - service worker may return 504 when token is no longer valid
      if (!(error.status === 403 || error.status === 504)) {
        handleHttpError(error, rdsToastService);
      }
      throw error;
    })
  );
};

const handleHttpError = (err: HttpErrorResponse, toast: RdsToastService) => {
  const message = err.url?.includes('/auth/v1/auth')
    ? 'Authorization error occurred'
    : (err.error?._embedded?.errors[0]?.message ??
      err.message ??
      'Unexpected error occurred');
  toast.error(message, 'An error occurred', {
    disableTimeOut: true,
  });
};
