import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  RdsAvatarComponent,
  RdsAvatarIconDirective,
  RdsAvatarImageDirective,
  RdsCustomIconComponent,
  RdsIconComponent,
} from '@rds/angular-components';

import {
  BookingRequestDetailsModel,
  RoomServicesApiResponseEnum,
  SERVICE_MODEL_ARRAY,
  ServiceViewModel,
} from '../../../booking-request';
import { EventDateTimePipe } from '../../../core';

@Component({
  selector: 'bsh-booking-summary',
  imports: [
    RdsIconComponent,
    EventDateTimePipe,
    RdsAvatarComponent,
    RdsAvatarImageDirective,
    RdsAvatarIconDirective,
    RdsCustomIconComponent,
    NgOptimizedImage,
  ],
  templateUrl: './booking-summary.component.html',
  styleUrl: './booking-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingSummaryComponent {
  @Input() set bookingDetails(details: BookingRequestDetailsModel) {
    this.selectedServices = [];
    this.details = details;
    this.services = structuredClone(SERVICE_MODEL_ARRAY);
    this.services.forEach(service => {
      const activeService = this.details?.services?.find(activeService => {
        return activeService.serviceType == service.service;
      });
      if (activeService) {
        const currentValue = this.services.get(activeService.serviceType);
        if (currentValue) {
          this.services.set(activeService.serviceType, currentValue);
          if (activeService.url) {
            currentValue.description = activeService.url;
            currentValue.isUrl = true;
          }
          this.selectedServices.push(currentValue);
        }
      }
    });
  }
  protected details: BookingRequestDetailsModel | null = null;
  protected services: Map<RoomServicesApiResponseEnum, ServiceViewModel> =
    structuredClone(SERVICE_MODEL_ARRAY);
  protected selectedServices: ServiceViewModel[] = [];
}
