import { RdsBadgeColor } from '@rds/angular-components';

import {
  BookingRequestBaseApiModel,
  BookingRequestCreateApiRequestModel,
  BookingRequestDetailsApiResponseModel,
  BookingRequestUpdateApiRequestModel,
  BookingStatusApiResponseEnum,
  ChatMessageApiResponseModel,
  RoomServicesApiResponseEnum,
} from './booking-request-api.model';
import { NotificationSourceTypeEnum } from '../../notifications';
import { RoomDetailsModel } from '../../rooms';

export interface RoomBookingModel {
  createTime: string;
  id: number;
  roomDescription: string;
  roomDexId: string;
  roomName: string;
  startDate: Date;
  endDate: Date;
  startTime: string;
  eventName: string;
  endTime: string;
  status: {
    value: BookingStatusEnum;
    text: string;
    color: RdsBadgeColor;
  };
  requester: {
    email: string;
    fullName: string;
    photo: string;
  };
}

export interface BookingRequestDetailsViewModel {
  bookingDetails: BookingRequestDetailsModel;
  roomDetails: RoomDetailsModel;
}

export interface BookingRequestDetailsModel
  extends Omit<
    BookingRequestDetailsApiResponseModel,
    'startTime' | 'endTime' | 'status' | 'createTime'
  > {
  createTime: Date;
  startTime: Date;
  status: {
    value: BookingStatusEnum;
    text: string;
    color: RdsBadgeColor;
  };
  endTime: Date;
}

export type BookingRequestDetailsUpdateModel =
  BookingRequestUpdateApiRequestModel;

export type BookingRequestCreateModel = BookingRequestCreateApiRequestModel;

export type BookingRequestUpdateModel = BookingRequestUpdateApiRequestModel;

export type BookingRequestBaseModel = BookingRequestBaseApiModel;

export type ChatMessageModel = ChatMessageApiResponseModel;

export type RoomServicesEnum = RoomServicesApiResponseEnum;
export const RoomServicesEnum = { ...RoomServicesApiResponseEnum };

export type BookingStatusEnum = BookingStatusApiResponseEnum;
export const BookingStatusEnum = { ...BookingStatusApiResponseEnum };

export interface BookingBasicData {
  bookingId: number;
  roomDexId: string;
  setActiveTab?: NotificationSourceTypeEnum;
}

export type CustomIcon = 'lock' | 'furniture' | 'catering' | 'bookly-short';

export interface ServiceViewModel {
  service: RoomServicesApiResponseEnum;
  customIcon: CustomIcon;
  name: string;
  description: string;
  isUrl: boolean;
}

export interface CalendarData {
  roomCalendarId?: string;
  startDate?: string | undefined;
}

export const SERVICE_MODEL_ARRAY: Map<
  RoomServicesApiResponseEnum,
  ServiceViewModel
> = new Map([
  [
    RoomServicesApiResponseEnum.Catering,
    {
      service: RoomServicesApiResponseEnum.Catering,
      customIcon: 'catering',
      name: 'Catering Service',
      description: '',
      isUrl: false,
    },
  ],
  [
    RoomServicesApiResponseEnum.Locker,
    {
      service: RoomServicesApiResponseEnum.Locker,
      customIcon: 'lock',
      name: 'Room Locking Service',
      description:
        'The request should be send no later than 48 hours in advance of the event and should last longer than 24 hours otherwise it may be rejected.',
      isUrl: false,
    },
  ],
  [
    RoomServicesApiResponseEnum.Furniture,
    {
      service: RoomServicesApiResponseEnum.Furniture,
      customIcon: 'furniture',
      name: 'Furniture Service',
      description: '',
      isUrl: false,
    },
  ],
]);
