import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  RdsButtonModule,
  RdsDialogModule,
  RdsDialogRef,
  RdsIconComponent,
  RdsProgressSpinnerModule,
} from '@rds/angular-components';

import { NotificationComponent } from '../../components/notification/notification.component';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'bsh-all-notifications-dialog',
  imports: [
    RdsDialogModule,
    RdsButtonModule,
    RdsIconComponent,
    RdsProgressSpinnerModule,
    NotificationComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './all-notifications-dialog.component.html',
  styleUrls: ['./all-notifications-dialog.component.scss'],
})
export class AllNotificationsDialogComponent {
  readonly #notificationsService = inject(NotificationsService);
  readonly #rdsDialogRef =
    inject<RdsDialogRef<AllNotificationsDialogComponent>>(RdsDialogRef);
  readonly latestNotifications = this.#notificationsService.allNotifications;
  readonly isLoading = this.#notificationsService.isLoading;

  closeAfterRedirect(close: boolean) {
    if (close) {
      this.#rdsDialogRef.close();
    }
  }
}
