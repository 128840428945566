<rds-carousel [showButtons]="true" [autoplay]="false">
  @for (carouselItem of photos; track carouselItem.id) {
    <rds-carousel-item>
      <div class="content-placeholder">
        <img
          [ngSrc]="carouselItem.url"
          alt="Room"
          width="100%"
          height="auto"
          priority />
      </div>
    </rds-carousel-item>
  }
</rds-carousel>
