@if (eventDetail) {
  <div class="container">
    <div class="row" [formGroup]="bookingDetailsForm">
      <div class="col-5">
        <div class="room-header">
          <div class="image-container">
            @if (
              eventDetail.roomDetails.photos &&
              eventDetail.roomDetails.photos.length > 0
            ) {
              <img
                [ngSrc]="eventDetail.roomDetails.photos[0].url"
                alt="Room photo"
                fill />
            }
          </div>
          <div>
            <p class="ui-heading-4-bold">
              {{ eventDetail.roomDetails.roomName }}
            </p>
            <p class="ui-heading-4-bold">
              {{ eventDetail.roomDetails.siteName }}
              {{ eventDetail.roomDetails.buildingName }}
              {{ eventDetail.roomDetails.floorCode }}
            </p>
            <p class="ui-label-s-bold">
              Capacity: up to {{ eventDetail.roomDetails.capacity }} people
            </p>
            <br />
            <button
              rds-icon-button
              size="m"
              #popover="rdsPopover"
              (click)="popover.toggle()"
              [rdsPopover]="popoverContent">
              <rds-icon namespace="outlined" icon="administration"></rds-icon>
            </button>
            <ng-template #popoverContent>
              <rds-popover-text-content>
                <h4 rds-popover-header>Room configuration</h4>
                <p rds-popover-paragraph>
                  @if (eventDetail.roomDetails.description) {
                    <p class="ui-label-s-bold">Description:</p>
                    <p class="ui-label-s">
                      {{ eventDetail.roomDetails.description }}
                    </p>
                  }
                  @if (eventDetail.roomDetails.manageSpaceConfiguration) {
                    <p class="ui-label-s-bold">Active:</p>
                    <p class="ui-label-s">
                      {{
                        eventDetail.roomDetails.manageSpaceConfiguration.active
                      }}
                    </p>
                    <p class="ui-label-s-bold">Automatic approval:</p>
                    <p class="ui-label-s">
                      {{
                        eventDetail.roomDetails.manageSpaceConfiguration
                          .automaticApproval
                      }}
                    </p>
                  }

                  @if (
                    eventDetail.roomDetails.manageSpaceConfiguration &&
                    eventDetail.roomDetails.manageSpaceConfiguration
                      .durationRange &&
                    eventDetail.roomDetails.manageSpaceConfiguration.durationRange.min.hasOwnProperty(
                      'minutes'
                    )
                  ) {
                    <p class="ui-label-s-bold">Meeting duration range:</p>
                    <p class="ui-label-s">
                      Min:
                      {{
                        eventDetail.roomDetails.manageSpaceConfiguration
                          .durationRange.min.days
                      }}
                      days,
                      {{
                        eventDetail.roomDetails.manageSpaceConfiguration
                          .durationRange.min.hours
                      }}
                      hours,
                      {{
                        eventDetail.roomDetails.manageSpaceConfiguration
                          .durationRange.min.minutes
                      }}
                      minutes
                    </p>
                    <p class="ui-label-s">
                      Max:
                      {{
                        eventDetail.roomDetails.manageSpaceConfiguration
                          .durationRange.max.days
                      }}
                      days,
                      {{
                        eventDetail.roomDetails.manageSpaceConfiguration
                          .durationRange.max.hours
                      }}
                      hours,
                      {{
                        eventDetail.roomDetails.manageSpaceConfiguration
                          .durationRange.max.minutes
                      }}
                      minutes
                    </p>
                  }

                  @if (
                    eventDetail.roomDetails.manageSpaceConfiguration
                      ?.blockingTimes
                  ) {
                    <p class="ui-label-s-bold">Room blocking time:</p>
                    @for (
                      blockingTime of eventDetail.roomDetails
                        .manageSpaceConfiguration?.blockingTimes;
                      track $index
                    ) {
                      <p class="ui-label-s">
                        {{ blockingTime.from }} - {{ blockingTime.to }}
                      </p>
                    }
                  }
                </p>
              </rds-popover-text-content>
            </ng-template>
          </div>
        </div>
        <div class="requester-details">
          <div>
            <p class="ui-label-l-bold">Requested by:</p>
            <rds-avatar size="m" disableInteractions="true" class="user-icon">
              @if (eventDetail.bookingDetails.requester.photo) {
                <img
                  rds-avatar-image
                  [ngSrc]="eventDetail.bookingDetails.requester.photo"
                  fill
                  alt="requester avatar"
                  referrerpolicy="no-referrer" />
              } @else {
                <rds-icon
                  rds-avatar-icon
                  namespace="outlined"
                  icon="user"></rds-icon>
              }
            </rds-avatar>
            <p class="ui-label-l">
              {{ eventDetail.bookingDetails.requester.fullName }}
            </p>
          </div>
          <div>
            <p class="ui-label-l-bold">Requested date:</p>
            <p class="ui-label-l">
              {{
                eventDetail.bookingDetails.createTime
                  | date: 'dd MMM yyyy, HH:mm'
              }}
            </p>
          </div>
        </div>
        <div class="room-details">
          <p class="ui-label-l-bold">Room Includes</p>
          <div class="room-includes">
            @for (
              feature of eventDetail.roomDetails.features;
              track feature.id
            ) {
              <p class="ui-label-l">{{ feature.name }}</p>
            }
          </div>
        </div>
        @if (layouts().length > 0) {
          <div class="container layout-container">
            <div class="row">
              <div class="col-7">
                <div class="form-control">
                  <rds-form-field labelPosition="top">
                    <rds-control-label>
                      Select Layout
                      <rds-icon
                        [rdsTooltip]="'Tooltip message'"
                        icon="info"
                        namespace="outlined">
                      </rds-icon>
                    </rds-control-label>
                    <rds-select formControlName="roomLayoutUUID">
                      @for (layout of layouts(); track layout.layoutDexUUID) {
                        <rds-select-option [value]="layout">
                          {{ layout.layoutName }}
                        </rds-select-option>
                      }
                    </rds-select>
                    <rds-control-error>Layout is required</rds-control-error>
                  </rds-form-field>
                  @if (bookingDetailsForm.controls.roomLayoutUUID.value) {
                    <p class="ui-label-s">
                      Layout capacity:
                      {{
                        bookingDetailsForm.controls.roomLayoutUUID.value
                          .capacity.min
                      }}
                      -
                      {{
                        bookingDetailsForm.controls.roomLayoutUUID.value
                          .capacity.max
                      }}
                    </p>
                    <p class="ui-label-s">Layout setup time:</p>
                    <p class="ui-label-s">
                      {{
                        bookingDetailsForm.controls.roomLayoutUUID.value
                          .setupTime.before
                      }}
                      minutes before -
                      {{
                        bookingDetailsForm.controls.roomLayoutUUID.value
                          .setupTime.after
                      }}
                      minutes after
                    </p>
                  }
                </div>
              </div>
              <div class="col-5">
                @if (
                  bookingDetailsForm.controls.roomLayoutUUID.value &&
                  bookingDetailsForm.controls.roomLayoutUUID.value.imageURL
                ) {
                  <div class="layout-image">
                    <img
                      alt="layout-image"
                      [ngSrc]="
                        bookingDetailsForm.controls.roomLayoutUUID.value
                          .imageURL
                      "
                      fill />
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </div>
      <div class="col-7">
        <div class="container">
          <div class="flex edit-buttons">
            @if (
              eventDetail.bookingDetails.status.value ===
              BookingStatusEnum.InProgress
            ) {
              @if (!isEditMode()) {
                <button
                  rds-secondary-button
                  size="m"
                  (click)="switchToEditMode()">
                  Edit
                </button>
              } @else {
                <button rds-secondary-button size="m" (click)="cancel()">
                  Cancel
                </button>
                <button
                  rds-primary-button
                  size="m"
                  [disabled]="bookingDetailsForm.invalid"
                  (click)="save()">
                  Save
                </button>
              }
            }
          </div>
          <div class="row">
            <div class="col-12">
              <rds-form-field labelPosition="top">
                <rds-control-label> Event name </rds-control-label>
                <input rdsInput formControlName="eventName" />
                @if (bookingDetailsForm.controls.eventName.errors) {
                  <rds-control-error>
                    This field is required
                  </rds-control-error>
                }
              </rds-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <rds-form-field labelPosition="top">
                <rds-control-label>Event Date/Time</rds-control-label>
                <rds-date-range-input
                  [rangePicker]="rangePicker"
                  [min]="minDate">
                  <input
                    rdsStartDate
                    placeholder="Start date"
                    formControlName="startDate" />
                  <input
                    rdsEndDate
                    placeholder="End date"
                    formControlName="endDate" />
                </rds-date-range-input>
                <rds-datepicker-toggle
                  rds-control-suffix></rds-datepicker-toggle>
                <rds-date-range-picker #rangePicker showToggle="false">
                </rds-date-range-picker>
                @if (bookingDetailsForm.controls.startDate.invalid) {
                  <rds-control-error>
                    Date cannot be from the past
                  </rds-control-error>
                }
              </rds-form-field>
            </div>
            <div class="col-6">
              <div class="flex">
                <div>
                  <rds-form-field labelPosition="top">
                    <rds-control-label
                      >No. of People (in room)</rds-control-label
                    >
                    <input
                      rdsInput
                      type="number"
                      formControlName="numberOfPeople" />
                    @if (
                      bookingDetailsForm.controls.numberOfPeople.errors?.['max']
                    ) {
                      <rds-control-error>
                        Maximum capacity:
                        {{
                          bookingDetailsForm.controls.roomLayoutUUID.value
                            ?.capacity?.max
                            ? bookingDetailsForm.controls.roomLayoutUUID.value
                                ?.capacity?.max
                            : eventDetail.roomDetails.capacity
                        }}
                      </rds-control-error>
                    }
                    @if (
                      bookingDetailsForm.controls.numberOfPeople.errors?.['min']
                    ) {
                      <rds-control-error>
                        Minimum capacity:
                        {{
                          bookingDetailsForm.controls.roomLayoutUUID.value
                            ?.capacity?.min
                            ? bookingDetailsForm.controls.roomLayoutUUID.value
                                ?.capacity?.min
                            : eventDetail.roomDetails.minCapacity
                              ? eventDetail.roomDetails.minCapacity
                              : 1
                        }}
                      </rds-control-error>
                    }
                  </rds-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="flex">
                <rds-form-field labelPosition="top">
                  <rds-control-label>Start time</rds-control-label>
                  <input
                    rdsInput
                    [rdsTimepicker]="startPicker"
                    formControlName="startHour" />
                  <rds-timepicker #startPicker />
                  <rds-timepicker-clear
                    rds-control-suffix
                    [forTimepicker]="startPicker" />
                  @if (bookingDetailsForm.errors?.['timeNotValid']) {
                    <rds-control-error>
                      Start time should be before end time
                    </rds-control-error>
                  } @else if (bookingDetailsForm.errors?.['timeFromPast']) {
                    <rds-control-error>
                      Event cannot start in the past
                    </rds-control-error>
                  } @else if (bookingDetailsForm.errors?.['durationTooShort']) {
                    <rds-control-error>
                      Meeting duration is too short
                    </rds-control-error>
                  } @else if (bookingDetailsForm.errors?.['durationTooLong']) {
                    <rds-control-error>
                      Meeting duration is too long
                    </rds-control-error>
                  } @else if (bookingDetailsForm.errors?.['blockingOverlap']) {
                    <rds-control-error>
                      Meeting overlaps with blocking time
                    </rds-control-error>
                  }
                </rds-form-field>
                <rds-form-field labelPosition="top">
                  <rds-control-label>End time</rds-control-label>
                  <input
                    rdsInput
                    [rdsTimepicker]="endPicker"
                    formControlName="endHour" />
                  <rds-timepicker #endPicker />
                  <rds-timepicker-clear
                    rds-control-suffix
                    [forTimepicker]="endPicker" />
                  @if (bookingDetailsForm.errors?.['timeNotValid']) {
                    <rds-control-error>
                      End time should be after start time
                    </rds-control-error>
                  }
                </rds-form-field>
              </div>
              <div class="input-wrapper">
                <rds-form-field labelPosition="top">
                  <rds-control-label>WBS</rds-control-label>
                  <input rdsInput formControlName="wbsCode" />
                </rds-form-field>
              </div>
              <div class="input-wrapper">
                <rds-form-field labelPosition="top">
                  <rds-control-label>Cost Center</rds-control-label>
                  <input rdsInput formControlName="costCenter" />
                  @if (bookingDetailsForm.controls.costCenter.invalid) {
                    <rds-control-error
                      >Cost center should be 10 digits number</rds-control-error
                    >
                  }
                </rds-form-field>
              </div>
            </div>
            <div class="col-6">
              <p class="ui-label-l">Request Additional Services</p>
              <rds-checkbox formControlName="furnitureService">
                Furniture Service
              </rds-checkbox>
              <rds-checkbox formControlName="cateringService">
                Catering Service
              </rds-checkbox>
              <rds-checkbox formControlName="lockerService">
                Room locking Service
              </rds-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
