import { Injectable, inject, signal } from '@angular/core';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { format } from 'date-fns/format';
import { filter, tap } from 'rxjs';

import { RoomDetailsModel, RoomLayoutsModel } from '../../rooms';
import { RoomServicesApiResponseEnum } from '../model/booking-request-api.model';
import {
  BookingRequestBaseModel,
  BookingRequestDetailsModel,
  RoomServicesEnum,
} from '../model/booking-request.model';
import {
  RoomBookingFormGroupModel,
  RoomBookingFormValueModel,
} from '../model/room-booking-form.model';
import { blockingTimeValidator } from '../validators/blocking-time.validator';
import { eventDurationValidator } from '../validators/event-duration.validator';
import { eventTimeValidator } from '../validators/event-time.validator';

// provide this service in the component
@Injectable()
export class RoomBookingFormService {
  readonly #fb = inject(FormBuilder);

  readonly #isEditMode = signal(false);
  readonly #roomBookingForm: RoomBookingFormGroupModel<RoomBookingFormValueModel> =
    this.#fb.group(
      {
        eventName: this.#fb.nonNullable.control<string>('', [
          Validators.required,
        ]),
        startDate: this.#fb.nonNullable.control<Date>(new Date(), [
          Validators.required,
        ]),
        endDate: this.#fb.nonNullable.control<Date>(new Date(), [
          Validators.required,
        ]),
        startHour: this.#fb.nonNullable.control<Date>(new Date(), [
          Validators.required,
        ]),
        endHour: this.#fb.nonNullable.control<Date>(new Date(), [
          Validators.required,
        ]),
        wbsCode: this.#fb.control<string>(''),
        costCenter: this.#fb.nonNullable.control<string>('', [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('^[0-9]*$'),
        ]),
        numberOfPeople: this.#fb.nonNullable.control<number>(0, [
          Validators.required,
          Validators.min(1),
        ]),
        roomLayoutUUID: this.#fb.control<RoomLayoutsModel | null>(null),
        furnitureService: this.#fb.control<boolean>(false),
        cateringService: this.#fb.control<boolean>(false),
        lockerService: this.#fb.control<boolean>(false),
      },
      { validators: [eventTimeValidator() as ValidatorFn] }
    );

  get roomBookingForm() {
    return this.#roomBookingForm;
  }

  get isEditMode() {
    return this.#isEditMode.asReadonly();
  }

  get layoutsControlValueChange() {
    return this.#roomBookingForm.controls.roomLayoutUUID.valueChanges.pipe(
      filter(Boolean),
      tap(selectedLayout => {
        this.#roomBookingForm.controls.numberOfPeople.setValidators([
          Validators.min(selectedLayout.capacity.min),
          Validators.max(selectedLayout.capacity.max),
        ]);
        this.#roomBookingForm.controls.numberOfPeople.updateValueAndValidity();
      })
    );
  }

  public setFormValues(bookingDetails: BookingRequestDetailsModel) {
    this.#roomBookingForm.patchValue({
      eventName: bookingDetails.eventName,
      startDate: bookingDetails.startTime,
      endDate: bookingDetails.endTime,
      startHour: bookingDetails.startTime,
      endHour: bookingDetails.endTime,
      wbsCode: bookingDetails.wbsCode,
      costCenter: bookingDetails.costCenter,
      numberOfPeople: bookingDetails.numberOfPeople,
      cateringService: bookingDetails.services?.some(
        service => service.serviceType === RoomServicesEnum.Catering
      ),
      furnitureService: bookingDetails.services?.some(
        service => service.serviceType === RoomServicesEnum.Furniture
      ),
      lockerService: bookingDetails.services?.some(
        service => service.serviceType === RoomServicesEnum.Locker
      ),
    });
  }

  public setEditMode(isEditMode: boolean) {
    if (isEditMode) {
      this.#roomBookingForm.enable();
      this.#roomBookingForm.markAllAsTouched();
    } else {
      this.#roomBookingForm.disable();
    }
    this.#isEditMode.set(isEditMode);
  }

  public setRoomDataValidators(roomDetails: RoomDetailsModel) {
    this.#roomBookingForm.controls.numberOfPeople.addValidators([
      Validators.max(roomDetails.capacity),
      Validators.min(roomDetails.minCapacity),
    ]);

    const min = roomDetails.manageSpaceConfiguration?.durationRange?.min;
    const max = roomDetails.manageSpaceConfiguration?.durationRange?.max;
    if (min && max) {
      this.#roomBookingForm.addValidators(
        eventDurationValidator(min, max) as ValidatorFn
      );
    }
    const blockingTimes = roomDetails.manageSpaceConfiguration?.blockingTimes;
    if (blockingTimes && blockingTimes.length > 0) {
      this.#roomBookingForm.addValidators(
        blockingTimeValidator(blockingTimes) as ValidatorFn
      );
    }
  }

  public setRoomLayoutsValidators(
    roomLayouts: RoomLayoutsModel[],
    layoutId?: string
  ) {
    const selectedLayout = roomLayouts.find(
      layout => layout.layoutDexUUID === layoutId
    );
    this.#roomBookingForm.controls.roomLayoutUUID.addValidators(
      Validators.required
    );
    if (selectedLayout) {
      this.#roomBookingForm.controls.roomLayoutUUID.patchValue(selectedLayout);
    }
  }

  public createRoomBookingRequestBody(): BookingRequestBaseModel {
    const formValue = this.#roomBookingForm.value as RoomBookingFormValueModel;
    const startTime = `${format(formValue.startDate, 'yyyy-MM-dd')}T${format(formValue.startHour, 'HH:mm')}:00+00:00`;
    const endTime = `${format(formValue.endDate, 'yyyy-MM-dd')}T${format(formValue.endHour, 'HH:mm')}:00+00:00`;
    const services: { serviceType: RoomServicesApiResponseEnum }[] = [];
    if (formValue.cateringService) {
      services.push({ serviceType: RoomServicesApiResponseEnum.Catering });
    }
    if (formValue.furnitureService) {
      services.push({ serviceType: RoomServicesApiResponseEnum.Furniture });
    }
    if (formValue.lockerService) {
      services.push({ serviceType: RoomServicesApiResponseEnum.Locker });
    }
    return {
      eventName: formValue.eventName,
      numberOfPeople: formValue.numberOfPeople,
      wbsCode: formValue.wbsCode,
      costCenter: formValue.costCenter,
      startTime,
      endTime,
      roomLayoutUUID: formValue.roomLayoutUUID
        ? formValue.roomLayoutUUID.layoutDexUUID
        : '',
      services,
    };
  }
}
