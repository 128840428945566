import { format } from 'date-fns/format';

import {
  RoomAvailabilityApiResponseModel,
  RoomCalendarEventsModel,
} from '@bookly/shared';

export function roomCalendarEventsConverter(
  roomAvailability: RoomAvailabilityApiResponseModel
): RoomCalendarEventsModel[] {
  return roomAvailability.ranges.map(roomEvent => ({
    start: new Date(roomEvent.start),
    end: new Date(roomEvent.end),
    title: roomEvent.userFullName ?? roomEvent.organizer,
    allDay: !roomEvent.end.includes('T'),
    meta: {
      timeStart: format(roomEvent.start, 'HH:mm'),
      timeEnd: format(roomEvent.end, 'HH:mm'),
      status: roomEvent.status ?? 'unknown',
      type: roomEvent.type ?? 'unknown',
      eventName:
        roomEvent.eventName ??
        (!roomEvent.title || roomEvent.title.length === 0
          ? 'Busy'
          : roomEvent.title),
      userFullName: roomEvent.userFullName ?? roomEvent.organizer,
      bookingId: roomEvent.bookingId ? +roomEvent.bookingId : null,
    },
  }));
}
