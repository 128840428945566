@let isMobileScreen = isMobileScreen$ | async;

<div class="room-calendar">
  @if (isLoading()) {
    <div class="loader">
      <rds-progress-spinner></rds-progress-spinner>
    </div>
  }

  @if (isMobileScreen?.matches) {
    <rds-form-field size="m" labelPosition="none">
      <input
        rdsInput
        [rdsDatepicker]="picker"
        [(ngModel)]="viewDate"
        (ngModelChange)="viewDateChange($event)" />
      <rds-datepicker-toggle rds-control-suffix />
      <rds-datepicker #picker />
    </rds-form-field>
    <div class="buttons-container">
      <button
        rds-text-button
        mwlCalendarPreviousView
        [view]="'day'"
        [(viewDate)]="viewDate"
        (viewDateChange)="viewDateChange(viewDate)">
        PREVIOUS DAY
      </button>
      <button
        rds-text-button
        mwlCalendarNextView
        [view]="'day'"
        [(viewDate)]="viewDate"
        (viewDateChange)="viewDateChange(viewDate)">
        NEXT DAY
      </button>
    </div>
    <mwl-calendar-day-view
      [viewDate]="viewDate"
      [events]="roomEvents()"
      [dayStartHour]="7"
      [hourSegments]="4"
      [eventTemplate]="customCellTemplate"
      [hourSegmentHeight]="20"
      [minimumEventHeight]="20"></mwl-calendar-day-view>
  } @else {
    <div class="buttons-container">
      <button
        rds-text-button
        mwlCalendarPreviousView
        [view]="'week'"
        [(viewDate)]="viewDate"
        (viewDateChange)="viewDateChange(viewDate)">
        PREVIOUS WEEK
      </button>
      <rds-form-field size="m" labelPosition="none">
        <input
          rdsInput
          [rdsDatepicker]="picker"
          [(ngModel)]="viewDate"
          (ngModelChange)="viewDateChange($event)" />
        <rds-datepicker-toggle rds-control-suffix />
        <rds-datepicker #picker />
      </rds-form-field>
      <button
        rds-text-button
        mwlCalendarNextView
        [view]="'week'"
        [(viewDate)]="viewDate"
        (viewDateChange)="viewDateChange(viewDate)">
        NEXT WEEK
      </button>
    </div>
    <mwl-calendar-week-view
      [viewDate]="viewDate"
      [events]="roomEvents()"
      [dayStartHour]="7"
      [hourSegments]="4"
      [class]="stickyHeader ? 'sticky-header' : null"
      [eventTemplate]="customCellTemplate"
      [hourSegmentHeight]="20"
      [minimumEventHeight]="20"
      [weekStartsOn]="1">
    </mwl-calendar-week-view>
  }
</div>

<ng-template #customCellTemplate let-weekEvent="weekEvent">
  @switch (weekEvent.event.meta.type) {
    @case (CalendarEventTypeEnum.BlockingEvent) {
      <div
        class="cal-event custom-event blocking-time"
        [rdsTooltip]="
          'BLOCKING TIME, ' +
          weekEvent.event.meta.timeStart +
          ' - ' +
          weekEvent.event.meta.timeEnd
        ">
        <p class="ui-body-s-bold">BLOCKING TIME</p>
        <p>
          {{ weekEvent.event.meta.timeStart }} -
          {{ weekEvent.event.meta.timeEnd }}
        </p>
      </div>
    }
    @case (CalendarEventTypeEnum.Event) {
      <div
        class="cal-event custom-event"
        [class]="weekEvent.event.meta.status"
        [ngClass]="
          weekEvent.event.meta.bookingId ===
            bookingRequest?.bookingDetails?.id &&
          weekEvent.event.meta.status === 'IN_PROGRESS'
            ? 'current'
            : null
        "
        [rdsTooltip]="
          weekEvent.event.meta.eventName +
          ', ' +
          'Booked by ' +
          weekEvent.event.meta.userFullName +
          ', ' +
          weekEvent.event.meta.timeStart +
          ' - ' +
          weekEvent.event.meta.timeEnd
        ">
        <p class="ui-body-s-bold">
          {{
            weekEvent.event.meta.eventName ??
              'Booked by: ' + weekEvent.event.meta.userFullName
          }}
        </p>
        <p>
          {{ weekEvent.event.meta.timeStart }} -
          {{ weekEvent.event.meta.timeEnd }}
        </p>
      </div>
    }
    @case (
      weekEvent.event.meta.type === CalendarEventTypeEnum.AfterSetupEvent ||
      weekEvent.event.meta.type === CalendarEventTypeEnum.BeforeSetupEvent
        ? weekEvent.event.meta.type
        : ''
    ) {
      <div
        class="cal-event custom-event"
        [class]="weekEvent.event.meta.status"
        [ngClass]="
          weekEvent.event.meta.bookingId ===
            bookingRequest?.bookingDetails?.id &&
          weekEvent.event.meta.status === 'IN_PROGRESS'
            ? 'current'
            : null
        "
        [rdsTooltip]="
          'Setup time' +
          ', ' +
          weekEvent.event.meta.timeStart +
          ' - ' +
          weekEvent.event.meta.timeEnd
        ">
        <p class="ui-body-s-bold">
          {{ 'Setup time' }}
        </p>
        <p>
          {{ weekEvent.event.meta.timeStart }} -
          {{ weekEvent.event.meta.timeEnd }}
        </p>
      </div>
    }
    @default {
      <div
        class="cal-event custom-event APPROVED"
        [rdsTooltip]="
          weekEvent.event.meta.eventName +
          ', ' +
          weekEvent.event.meta.timeStart +
          ' - ' +
          weekEvent.event.meta.timeEnd
        ">
        @if (weekEvent.event.allDay) {
          <p class="ui-body-s-bold">
            {{ weekEvent.event.meta.eventName }}
          </p>
        } @else {
          <p class="ui-body-s-bold">{{ weekEvent.event.meta.eventName }}</p>
          <p>
            {{ weekEvent.event.meta.timeStart }} -
            {{ weekEvent.event.meta.timeEnd }}
          </p>
        }
      </div>
    }
  }
</ng-template>
