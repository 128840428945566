import { Injectable, inject, signal } from '@angular/core';
import { finalize, forkJoin, tap } from 'rxjs';

import {
  BookingRequestApiService,
  BookingRequestDetailsModel,
  BookingRequestDetailsUpdateModel,
  BookingRequestDetailsViewModel,
  BookingStatusEnum,
  GoogleAnalyticsEventCategory,
  GoogleAnalyticsEventName,
  GoogleAnalyticsService,
  RoomBookingModel,
  RoomDetailsModel,
  RoomsApiService,
  statusConverter,
} from '@bookly/shared';

@Injectable({
  providedIn: 'root',
})
export class BookingRequestService {
  readonly #bookingRequestApiService = inject(BookingRequestApiService);
  readonly #roomsApiService = inject(RoomsApiService);
  readonly #googleAnalyticService = inject(GoogleAnalyticsService);

  readonly #bookingDetailsSignal =
    signal<BookingRequestDetailsViewModel | null>(null);
  readonly #pendingRequestsSignal = signal<RoomBookingModel[]>([]);
  readonly #allBookingsSignal = signal<RoomBookingModel[]>([]);
  readonly #approvedBookingsSignal = signal<RoomBookingModel[]>([]);
  readonly #isRoomBookingDataLoadingSignal = signal(false);
  readonly #isDetailsDataLoadingSignal = signal(false);
  readonly #roomDetailsSignal = signal<RoomDetailsModel | null>(null);
  readonly #searchQuerySignal = signal<string | null>(null);
  #roomBookingsSnapshot: RoomBookingModel[] = [];

  get allBookings() {
    return this.#allBookingsSignal.asReadonly();
  }

  get bookingDetails() {
    return this.#bookingDetailsSignal.asReadonly();
  }

  get roomDetails() {
    return this.#roomDetailsSignal.asReadonly();
  }

  get pendingRequests() {
    return this.#pendingRequestsSignal.asReadonly();
  }

  get approveBookings() {
    return this.#approvedBookingsSignal.asReadonly();
  }

  get isDetailsDataLoading() {
    return this.#isDetailsDataLoadingSignal.asReadonly();
  }

  get isRoomBookingDataLoading() {
    return this.#isRoomBookingDataLoadingSignal.asReadonly();
  }

  get searchQuery() {
    return this.#searchQuerySignal.asReadonly();
  }

  public getBookingRequestData(roomId: string, bookingId: number) {
    this.#bookingDetailsSignal.set(null);
    this.#isDetailsDataLoadingSignal.set(true);
    return forkJoin([
      this.#bookingRequestApiService.getBookingDetails(bookingId),
      this.#roomsApiService.getRoomDetails(roomId),
    ]).pipe(
      tap(([bookingRequest, roomDetails]) => {
        this.#bookingDetailsSignal.set({
          bookingDetails: bookingRequest,
          roomDetails: roomDetails,
        });
        this.#roomDetailsSignal.set(roomDetails);
      }),
      finalize(() => this.#isDetailsDataLoadingSignal.set(false))
    );
  }

  public getRoomBookings() {
    this.#isRoomBookingDataLoadingSignal.set(true);
    return this.#bookingRequestApiService.getRoomBookings().pipe(
      tap(roomBookings => {
        this.#pendingRequestsSignal.set(
          roomBookings.filter(
            roomBooking =>
              roomBooking.status.value === BookingStatusEnum.InProgress
          )
        );
        this.#approvedBookingsSignal.set(
          roomBookings.filter(
            roomBooking =>
              roomBooking.status.value === BookingStatusEnum.Approved
          )
        );
        this.#allBookingsSignal.set(roomBookings);
        this.#roomBookingsSnapshot = roomBookings;
      }),
      finalize(() => this.#isRoomBookingDataLoadingSignal.set(false))
    );
  }

  public searchRoomBookings(searchQuery: string) {
    this.#searchQuerySignal.set(searchQuery);
    const filtered = this.getFiltered(searchQuery, this.#roomBookingsSnapshot);

    this.#pendingRequestsSignal.set(
      filtered.filter(
        roomBooking => roomBooking.status.value === BookingStatusEnum.InProgress
      )
    );
    this.#approvedBookingsSignal.set(
      filtered.filter(
        roomBooking => roomBooking.status.value === BookingStatusEnum.Approved
      )
    );
    this.#allBookingsSignal.set(filtered);
  }

  public resetSearchRoomBookings() {
    this.#searchQuerySignal.set(null);
    this.#pendingRequestsSignal.set(
      this.#roomBookingsSnapshot.filter(
        roomBooking => roomBooking.status.value === BookingStatusEnum.InProgress
      )
    );
    this.#approvedBookingsSignal.set(
      this.#roomBookingsSnapshot.filter(
        roomBooking => roomBooking.status.value === BookingStatusEnum.Approved
      )
    );
    this.#allBookingsSignal.set(this.#roomBookingsSnapshot);
  }

  public clearSearchQuery() {
    this.#searchQuerySignal.set(null);
  }

  public approveBookingRequest(id: number, message: string) {
    this.#isDetailsDataLoadingSignal.set(true);
    return this.#bookingRequestApiService
      .approveRoomBooking({ id, message })
      .pipe(
        tap(bookingRequest => {
          this.updateRequestStatus(
            id,
            bookingRequest.status.value,
            bookingRequest
          );
          this.#googleAnalyticService.trackEvent(
            GoogleAnalyticsEventName.Approve,
            GoogleAnalyticsEventCategory.BookingRequest,
            `Request ID: ${id}`
          );
        }),
        finalize(() => this.#isDetailsDataLoadingSignal.set(false))
      );
  }

  public rejectBookingRequest(id: number, message: string) {
    this.#isDetailsDataLoadingSignal.set(true);
    return this.#bookingRequestApiService
      .rejectRoomBooking({ id, message })
      .pipe(
        tap(bookingRequest => {
          this.updateRequestStatus(
            id,
            bookingRequest.status.value,
            bookingRequest
          );
          this.#googleAnalyticService.trackEvent(
            GoogleAnalyticsEventName.Reject,
            GoogleAnalyticsEventCategory.BookingRequest,
            `Request ID: ${id}`
          );
        }),
        finalize(() => this.#isDetailsDataLoadingSignal.set(false))
      );
  }

  public cancelBookingRequest(id: number, message: string) {
    this.#isDetailsDataLoadingSignal.set(true);
    return this.#bookingRequestApiService
      .cancelRoomBooking({ id, message })
      .pipe(
        tap(bookingRequest => {
          this.updateRequestStatus(
            id,
            bookingRequest.status.value,
            bookingRequest
          );
          this.#googleAnalyticService.trackEvent(
            GoogleAnalyticsEventName.Cancel,
            GoogleAnalyticsEventCategory.BookingRequest,
            `Request ID: ${id}`
          );
        }),
        finalize(() => this.#isDetailsDataLoadingSignal.set(false))
      );
  }

  public updateBookingRequest(
    bookingRequest: BookingRequestDetailsUpdateModel
  ) {
    this.#isDetailsDataLoadingSignal.set(true);
    return this.#bookingRequestApiService
      .updateRoomBooking(bookingRequest)
      .pipe(
        tap(bookingRequest => {
          this.#bookingDetailsSignal.set({
            bookingDetails: bookingRequest,
            roomDetails: this.#bookingDetailsSignal()
              ?.roomDetails as RoomDetailsModel,
          });
          this.updateRequestDetails(bookingRequest);
          this.#googleAnalyticService.trackEvent(
            GoogleAnalyticsEventName.EditAdmin,
            GoogleAnalyticsEventCategory.BookingRequest,
            `Request ID: ${bookingRequest.id}`
          );
        }),
        finalize(() => this.#isDetailsDataLoadingSignal.set(false))
      );
  }

  private updateRequestStatus(
    bookingId: number,
    status: BookingStatusEnum,
    bookingRequest: BookingRequestDetailsModel
  ) {
    const searchQuery = this.searchQuery();
    const updatedRequestIndex = this.#roomBookingsSnapshot.findIndex(
      roomBooking => roomBooking.id === bookingId
    );
    this.#roomBookingsSnapshot[updatedRequestIndex].status =
      statusConverter(status);

    const allRequests = searchQuery
      ? this.getFiltered(searchQuery, this.#roomBookingsSnapshot)
      : [...this.#roomBookingsSnapshot];
    const updatedRequests = allRequests.filter(
      bookingRequest =>
        bookingRequest.status.value === BookingStatusEnum.InProgress
    );
    const approvedRequests = allRequests.filter(
      bookingRequest =>
        bookingRequest.status.value === BookingStatusEnum.Approved
    );

    this.#bookingDetailsSignal.set({
      bookingDetails: bookingRequest,
      roomDetails: this.#bookingDetailsSignal()
        ?.roomDetails as RoomDetailsModel,
    });
    this.#pendingRequestsSignal.set(updatedRequests);
    this.#approvedBookingsSignal.set(approvedRequests);

    if (allRequests.length < 1) return;

    this.#allBookingsSignal.set(allRequests);
  }

  private updateRequestDetails(bookingRequest: BookingRequestDetailsModel) {
    const updatedRequestIndex = this.#roomBookingsSnapshot.findIndex(
      roomBooking => roomBooking.id === bookingRequest.id
    );
    this.#roomBookingsSnapshot[updatedRequestIndex] = {
      ...this.#roomBookingsSnapshot[updatedRequestIndex],
      eventName: bookingRequest.eventName,
      startDate: new Date(bookingRequest.startTime),
      endDate: new Date(bookingRequest.endTime),
      startTime: new Date(bookingRequest.startTime).toLocaleString(['en-GB'], {
        hour: '2-digit',
        minute: '2-digit',
      }),
      endTime: new Date(bookingRequest.endTime).toLocaleString(['en-GB'], {
        hour: '2-digit',
        minute: '2-digit',
      }),
    };
    const searchQuery = this.searchQuery();
    const allRequests = searchQuery
      ? this.getFiltered(searchQuery, this.#roomBookingsSnapshot)
      : [...this.#roomBookingsSnapshot];
    const updatedRequests = allRequests.filter(
      roomBooking => roomBooking.status.value === BookingStatusEnum.InProgress
    );

    this.#pendingRequestsSignal.set(updatedRequests);
    this.#allBookingsSignal.set(allRequests);
  }

  private getFiltered(searchQuery: string, bookings: RoomBookingModel[]) {
    return bookings.filter(
      roomBooking =>
        roomBooking.id === +searchQuery ||
        roomBooking.roomDescription
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        roomBooking.roomName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        roomBooking.eventName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
}
