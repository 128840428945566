import { DatePipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { RdsBadgeComponent, RdsDialogService } from '@rds/angular-components';

import {
  DestinationFrontendEnum,
  NotificationSourceTypeEnum,
  NotificationStatusEnum,
  NotificationsModel,
  NotificationsService,
} from '@bookly/shared';

import { BookingRequestDetailsComponent } from '../../../../../../bookly-admin/src/lib/booking-request/views/booking-request-details/booking-request-details.component';

@Component({
  selector: 'bsh-notification',
  imports: [NgClass, RdsBadgeComponent, DatePipe],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input() notification: NotificationsModel | null = null;
  @Output() public isRedirectSuccessful = new EventEmitter<boolean>(false);
  readonly #dialogService = inject(RdsDialogService);
  readonly #notificationsService = inject(NotificationsService);
  readonly #router = inject(Router);
  protected readonly NotificationStatusEnum = NotificationStatusEnum;
  protected readonly NotificationSourceTypeEnum = NotificationSourceTypeEnum;

  protected handleNotificationClick() {
    if (this.notification?.status === NotificationStatusEnum.Unread) {
      this.#notificationsService
        .markNotificationAsRead(this.notification.notificationId)
        .subscribe();
      this.notification.status = NotificationStatusEnum.Read;
      this.#notificationsService.decreaseCounter();
    }
    if (
      this.notification?.destinationFrontend === DestinationFrontendEnum.User
    ) {
      this.#router
        .navigate(
          [
            'dashboard',
            'bookings',
            this.notification.attributes.bookingId,
            this.notification.attributes.roomDexId,
          ],
          { queryParams: { setActiveTab: this.notification.sourceService } }
        )
        .then(successful => {
          this.isRedirectSuccessful.emit(successful);
        });
      return;
    }
    this.#dialogService.open(BookingRequestDetailsComponent, {
      disableClose: true,
      size: 'xl',
      maxHeight: '100%',
      data: {
        bookingId: this.notification?.attributes.bookingId,
        roomDexId: this.notification?.attributes.roomDexId,
        setActiveTab: this.notification?.sourceService,
      },
    });
  }
}
